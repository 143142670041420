import React from "react";
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import IDManualEntryForm from "../forms/IDManualEntryForm";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import AddressSuggestionModal from "../components/modals/AddressSuggestionModal";

export const content = {
  "en-US": {
    heading_has_id: "Enter the information on your ID",
    heading_has_ssn: "Enter the information on your ID",
    paragraph_has_id: "Please fill out the form below based on the information on your Wisconsin ID. Please note: this is not where you update your name or address. This information should be exactly as shown on your ID.",
    paragraph_has_ssn: "Since you don\'t have a Wisconsin ID, we can use the last four digits of your social security number to get you registered.",
  },
  "es-ES": {
    heading_has_id: "Ingrese la información de su ID",
    heading_has_ssn: "Ingrese la información de su ID",
    paragraph_has_id: "Complete el siguiente formulario según la información de su ID de Wisconsin. Tenga en cuenta que no es aquí donde puede actualizar su nombre o su dirección. Esta información debe ser exactamente igual a la que se muestra en su ID.",
    paragraph_has_ssn: "Dado que no tiene una ID de Wisconsin, podemos usar los últimos cuatro dígitos de su número de seguro social para registrarlo.",
  }
};

const IDInformationPage = () => {
  const [showAddressConfirmationModal, setShowAddressConfirmationModal] = React.useState(false);

  const identityType = useSelector((state) => state.registrant.identity_type);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    dispatch(updateRegistrant({
      ...data
    }));

    setShowAddressConfirmationModal(true);
  };

  React.useEffect(() => {
    dispatch(changeStep([4, 6]));
  }, []);

  return (
    <>
      <Wrapper>
        <ContentLeft>
          <Heading1>{identityType === 'has_id' ? content[currentLanguage].heading_has_id : content[currentLanguage].heading_has_ssn}.</Heading1>
          <Paragraph>
            {identityType === 'has_id' ? content[currentLanguage].paragraph_has_id : content[currentLanguage].paragraph_has_ssn}
          </Paragraph>
        </ContentLeft>
        <ContentRight>
          <IDManualEntryForm onSubmit={handleSubmit} />
        </ContentRight>
      </Wrapper>
      <AddressSuggestionModal showAddressConfirmationModal={showAddressConfirmationModal} setShowAddressConfirmationModal={setShowAddressConfirmationModal} />
    </>
  );
}
export default IDInformationPage;